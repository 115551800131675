import React from "react";
import ScrollAnimation from "react-animate-on-scroll";



const SlipThree = () => {
  return (
    <div className="rn-splite-style">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="split-inner">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <h4 className="title">Company Infos</h4>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div className="row">
                <div className="col-6">
                  <h5>Germany</h5>
                  <p className="description">
                    CARLITA TECH Hausbrucherstr.130d <br /> 21147 Hamburg <br />{" "}
                    Deutschland 78543940267 <br /> Tel.: 017664843128 <br />{" "}
                    E-Mail: info@incarlisle.de <br /> Job Title: Software
                    Developer
                  </p>
                </div>

                <div className="col-6">
                  <h5>Qatar</h5>
                  <p className="description">
                    CARLITA TECH Hausbrucherstr.130d <br /> 21147 Hamburg <br />{" "}
                    Deutschland 78543940267 <br /> Tel.: 017664843128 <br />{" "}
                    E-Mail: info@incarlisle.de <br /> Job Title: Software
                    Developer
                  </p>
                </div>
              </div>

              <h5>Dispute resolution:</h5>
              <p className="description">
                In accordance with the regulation on online dispute resolution
                in consumer matters (ODR regulation), we would like to inform
                you about the online dispute resolution platform (OS platform).
                Consumers have the option of submitting complaints to the
                European Commission's online dispute resolution platform at
                http://ec.europa.eu/odr?tid=321294311. You will find the
                necessary contact details above in our imprint. However, we
                would like to point out that we are not willing or obliged to
                participate in dispute settlement procedures before a consumer
                arbitration board.
              </p>

              <h5>Liability for the content of this website</h5>
              <p className="description">
                We are constantly developing the content of this website and
                strive to provide correct and up-to-date information. According
                to the Telemedia Act (TMG) §7 (1), we as a service provider are
                responsible for our own information that we make available for
                use in accordance with general laws. Unfortunately, we cannot
                accept liability for the correctness of any content on this
                website, especially that provided by third parties. As a service
                provider within the meaning of §§ 8 to 10, we are not obliged to
                monitor the information you transmit or store or to investigate
                circumstances that indicate illegal activity. Our obligations to
                remove information or to block the use of information under the
                general laws due to court or official orders remain unaffected
                even if we are not responsible according to §§ 8 to 10.
                <br />
                <br />
                If you notice any problematic or illegal content, please contact
                us immediately so that we can remove the illegal content. You
                will find the contact details in the imprint.
              </p>

              <h5>L Copyright Notice</h5>
              <p className="description">
                All contents of this website (images, photos, texts, videos) are
                subject to the copyright of the Federal Republic of Germany.
                Please ask us before distributing, reproducing or exploiting the
                contents of this website, such as republishing them on other
                websites. If necessary, we will prosecute the unauthorized use
                of parts of the content of our site.
                <br />
                If you find content on this website that violates copyright,
                please contact us.
              </p>

              <h5> Picture credit</h5>
              <p className="description">
                The images, photos and graphics on this website are protected by
                copyright.
                <br />
                The image rights belong to the following photographers and
                companies: Carlita Tech & Top Web Design
              </p>
            </ScrollAnimation>

            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <h4 className="title">Privacy</h4>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <h5>Table of Contents:</h5>
              <p className="description">
                • Introduction and overview • Scope of application • Legal bases
                • Duration of storage • Communication • Web hosting • Cookies •
                Web analytics • Google Analytics data protection declaration •
                Google Ads (Google AdWords) conversion tracking data protection
                declaration
              </p>

              <h5>Introduction and overview:</h5>
              <p className="description">
                We have written this data protection declaration (version
                08/21/2021-321294311) in order to explain to you in accordance
                with the provisions of the General Data Protection Regulation
                (EU) 2016/679 and applicable national laws, which personal data
                (data for short) we as the person responsible - and that of
                processors commissioned by us (e.g. providers) - process, will
                process in the future and what legal options you have. The terms
                used are to be understood as gender-neutral. In short: We inform
                you comprehensively about data that we process about you.
                Privacy statements usually sound very technical and use legal
                jargon. This data protection declaration, on the other hand, is
                intended to describe the most important things as simply and
                transparently as possible. As far as transparency is conducive,
                technical terms are explained in a reader-friendly way, links to
                further information are provided and graphics are used. We are
                thus informing you in clear and simple language that we only
                process personal data in the course of our business activities
                if there is a corresponding legal basis. This is certainly not
                possible if you make the most concise, unclear and
                legal-technical statements possible, as they are often standard
                on the Internet when it comes to data protection. I hope that
                you find the following explanations interesting and informative
                and that you may find some information that you did not already
                know. If you still have questions, we would like to ask you to
                contact the responsible person named below or in the imprint, to
                follow the links provided and to look at further information on
                third-party websites. Our contact details can of course also be
                found in the imprint.
              </p>

              <h5>scope of application</h5>
              <p className="description">
                This data protection declaration applies to all personal data
                processed by us in the company and to all personal data that
                companies commissioned by us (processors) process. By personal
                data we mean information within the meaning of Art. 4 No. 1 GDPR
                such as a person's name, e-mail address and postal address. The
                processing of personal data ensures that we can offer and bill
                our services and products, whether online or offline. The scope
                of this data protection declaration includes: This data
                protection declaration applies to all personal data processed by
                us in the company and to all personal data that companies
                commissioned by us (processors) process. By personal data we
                mean information within the meaning of Art. 4 No. 1 GDPR such as
                a person's name, e-mail address and postal address. The
                processing of personal data ensures that we can offer and bill
                our services and products, whether online or offline. The scope
                of this data protection declaration includes: This data
                protection declaration applies to all personal data processed by
                us in the company and to all personal data that companies
                commissioned by us (processors) process. By personal data we
                mean information within the meaning of Art. 4 No. 1 GDPR such as
                a person's name, e-mail address and postal address. The
                processing of personal data ensures that we can offer and bill
                our services and products, whether online or offline. The scope
                of this privacy policy includes: • All online presences
                (websites, online shops) that we operate • Social media
                appearances and email communication • mobile apps for
                smartphones and other devices
                <br />
                <br />
                In short: The data protection declaration applies to all areas
                in which personal data is processed in a structured manner in
                the company via the channels mentioned. If we enter into legal
                relationships with you outside of these channels, we will inform
                you separately if necessary.
              </p>

              <h5> legal bases</h5>
              <p className="description">
                In the following data protection declaration, we provide you
                with transparent information on the legal principles and
                regulations, i.e. the legal basis of the General Data Protection
                Regulation, which enable us to process personal data. As far as
                EU law is concerned, we refer to REGULATION (EU) 2016/679 OF THE
                EUROPEAN PARLIAMENT AND OF THE COUNCIL of April 27, 2016. You
                can of course read this EU General Data Protection Regulation
                online on EUR-Lex, the gateway to the EU -Legal, see
                https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679.
                We only process your data if at least one of the following
                conditions applies: 1. Consent (Article 6 Paragraph 1 lit. a
                GDPR): You have given us your consent to process data for a
                specific purpose. An example would be storing the data you
                entered on a contact form. 2. Contract (Article 6 Paragraph 1
                lit. b GDPR): In order to fulfill a contract or pre-contractual
                obligations with you, we process your data. For example, if we
                conclude a sales contract with you, we need personal information
                in advance. 3. Legal obligation (Article 6 Paragraph 1 lit. c
                GDPR): If we are subject to a legal obligation, we process your
                data. For example, we are required by law to keep invoices for
                accounting purposes. These usually contain personal data. 4.
                Legitimate interests (Article 6 Paragraph 1 lit. f GDPR): In the
                case of legitimate interests that do not restrict your
                fundamental rights, we reserve the right to process personal
                data. For example, we need to process certain data in order to
                be able to operate our website securely and economically. This
                processing is therefore a legitimate interest.
                <br />
                <br />
                Other conditions such as the perception of recordings in the
                public interest and the exercise of public authority as well as
                the protection of vital interests do not usually apply to us. If
                such a legal basis should be relevant, it will be shown in the
                appropriate place. In addition to the EU regulation, national
                laws also apply: • In Austria, this is the Federal Law for the
                Protection of Natural Persons in the Processing of Personal Data
                (Data Protection Law), DSG for short. • The Federal Data
                Protection Act, BDSG for short, applies in Germany. If other
                regional or national laws apply, we will inform you about them
                in the following sections.
              </p>

              <h5> storage duration</h5>
              <p className="description">
                The fact that we only store personal data for as long as is
                absolutely necessary for the provision of our services and
                products is a general criterion for us. This means that we
                delete personal data as soon as the reason for the data
                processing no longer exists. In some cases, we are legally
                obliged to store certain data even after the original purpose
                has ceased to exist, for example for accounting purposes. If you
                wish your data to be deleted or revoke your consent to data
                processing, the data will be deleted as quickly as possible and
                provided there is no obligation to store it.
                <br />
                We will inform you below about the specific duration of the
                respective data processing, provided that we have further
                information on this.
              </p>

              <h5> communication</h5>
              <p className="description">
                👥 Affected: Anyone who communicates with us by phone, email or
                online form <br />📓 Processed data: e.g. B. Telephone number, name,
                e-mail address, entered form data. More details can be found
                under the type of contact used <br />🤝 Purpose: Handling of
                communication with customers, business partners, etc. <br />📅
                Duration of storage: Duration of the business case and the legal
                regulations <br />⚖️ Legal bases: Article 6(1)(a) GDPR (consent),
                Article 6(1)(b) GDPR (contract), Article 6(1)(f) GDPR
                (legitimate interests) <br />If you contact us and communicate by
                telephone, e-mail or online form, personal data may be
                processed. The data will be processed for the handling and
                processing of your question and the related business
                transaction. The data is stored for as long as the law requires.
                Affected people: All those who seek contact with us via the
                communication channels provided by us are affected by the
                processes mentioned. Phone: If you call us, the call data will
                be stored pseudonymously on the respective end device and with
                the telecommunications provider used. In addition, data such as
                name and telephone number can then be sent by e-mail and saved
                to answer enquiries. The data will be deleted as soon as the
                business case has ended and legal requirements permit. <br />E-mail:
                If you communicate with us by e-mail, data may be stored on the
                respective end device (computer, laptop, smartphone,...) and
                data is stored on the e-mail server. The data will be deleted as
                soon as the business case has ended and legal requirements
                permit. Online forms: If you communicate with us using an online
                form, data will be stored on our web server and, if necessary,
                forwarded to an e-mail address from us. The data will be deleted
                as soon as the business case has ended and legal requirements
                permit. <br />Legal bases: The processing of the data is based on the
                following legal bases: <br />• Article 6(1)(a) GDPR (consent): You
                give us your consent to store your data and to continue using it
                for business-related purposes; <br />• Article 6(1)(b) GDPR
                (contract): There is a need to fulfill a contract with you or a
                processor such as e.g. B. the telephone provider or we need the
                data for pre-contractual activities, such. B. the preparation of
                an offer, process; <br />• Article 6 paragraph 1 lit. f GDPR
                (legitimate interests): We want to conduct customer inquiries
                and business communication in a professional framework. These
                are certain technical facilities such. E-mail programs, exchange
                servers and mobile phone operators are necessary in order to be
                able to communicate efficiently.
              </p>
            </ScrollAnimation>

          </div>
        </div>
      </div>
    </div>
  );
};
export default SlipThree;
