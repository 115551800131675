import React from 'react';
import { FiCheck } from "react-icons/fi";

const PricingTwo = () => {
    return (
        <div className="row row--15">

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-2">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">عادي</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">٩٥٠</span>
                                </div>
                                <span className="subtitle">تدفع مرة واحدة</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck />مدير شخصي</li>
                                <li><FiCheck /> التعديلات الشخصية</li>
                                <li><FiCheck /> ربط النظام مع الصفحات </li>
                                <li><FiCheck /> نظام الدفع</li>
                                <li><FiCheck /> إعداد النظام</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-2 active">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">بروفيشنال</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">٢.١٠٠</span>
                                </div>
                                <span className="subtitle">تدفع مرة واحدة</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> تنصيب موقع الويب الخاص بك</li>
                                <li><FiCheck /> تصميم فردي</li>
                                <li><FiCheck /> واجهة مستخدم تكيفية</li>
                                <li><FiCheck /> سيرفر مدفوع</li>
                                <li><FiCheck /> دومين خاص لك</li>
                                <li><FiCheck /> النسخ الاحتياطي والأمان</li>
                                <li><FiCheck /> التحديثات</li>
                                <li><FiCheck />  خدمة </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

             {/* Start PRicing Table Area  */}
             <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-2">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title"> كارليتا غوو </h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">٢.٥٩٩</span>
                                </div>
                                <span className="subtitle">تدفع مرة واحدة</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> تنصيب موقع الويب الخاص بك</li>
                                <li><FiCheck /> تصميم فردي</li>
                                <li><FiCheck /> واجهة مستخدم تكيفية</li>
                                <li><FiCheck /> سيرفر مدفوع</li>
                                <li><FiCheck /> دومين خاص لك</li>
                                <li><FiCheck /> النسخ الاحتياطي والأمان</li>
                                <li><FiCheck /> تحسين محركات البحث الاحترافي</li>
                                <li><FiCheck />  خدمة ٢٤ ساعة / ٧ أيام</li>
                                <li><FiCheck /> التحديثات</li>
                                <li><FiCheck /> آبل آيباد </li>
                          
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}
        </div>
    )
}
export default PricingTwo;