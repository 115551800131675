import React from 'react';
import SEO from "../../common/SEOAR";
import HeaderOneAr from '../../common/header/HeaderOneAr';
import HeaderTopBarAr from '../../common/header/HeaderTopBarAr';
import FooterThreeAr from '../../common/footer/FooterThreeAr';

import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AboutTwoAr from "../../elements/about/AboutTwoAr";
import AdvanceTabOneAr from '../../elements/advancetab/AdvanceTabOneAr';
import BrandTwoAr from '../../elements/brand/BrandTwoAr';
import ServiceThreeAr from '../../elements/service/ServiceThreeAr';
import Separator from "../../elements/separator/Separator";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import TimelineTwoAr from "../../elements/timeline/TimelineTwoAr";
import CalltoActionSixAr from "../../elements/calltoaction/CalltoActionSixAr";


const Marketing = () => {
    return (
        <>
            <SEO title="لماذا نحن " />
            <main className="page-wrapper">
                
                <HeaderTopBarAr />
                <HeaderOneAr btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
           
                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-750 mb--0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 order-1 order-lg-1">
                                <div className="thumbnail">
                                    <img src="../images/bg/cover-right_ca.png" alt="Banner Images" />
                                </div>
                            </div>
                            <div className="col-lg-8 order-2 order-lg-2 mt_md--40 mt_sm--40">
                                <div className="inner text-right">
                                    <h1 className="title theme-gradient display-two"> نظام التأجير الذي يميزك في كل مرة <br />  {" "}
                                        <Typed
                                            strings={[
                                                "تأجير السيارات",
                                                " استئجار التملّك ",
                                                "تأجير القوارب",
                                                "آلات البناء",
                                                "دراجات وسكوتر",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                        <h2 className="description pr--0 pl--0">
                                            مع نظام كارليتا تمتع بتجربة نظام تأجير شامل يتيح لك إدارة أعمالك بكفاءة أكبر
                                            <br /> الإدارة والتخطيط والفواتير مجتمعة في نظام واحد بديهي   
                                        <br />
                                        </h2>
                                    <div className="button-group">
                                       {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="#">New Customer <i className="icon"><FiArrowRight /></i></Link> */} 
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/contactar"> اتصل بنا <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
  
                <CalltoActionSixAr />
  
                <Separator />
                {/* Start Service Area  */}
                    <ServiceThreeAr 
                        serviceStyle = "service__style--2"
                        textAlign = "text-center"
                    />
                    <br />
                {/* End Service Area  */}
                <Separator />

                {/* End Slider Area  */}
                <AboutTwoAr />
                {/* Start Service Area  */}
                <Separator />


                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "يمكنك الحصول على كل شيء"
                                    title = " انظمة التأجير متعدد الاستخدامات،  كذلك كارليتا "
                                    description = " مع كارليتا يمكنك التحكم بشكل ديجتال بعملية الإيجار والمبيعات الخاصة بك وتحسينها. من خلال خيارات التخصيص المختلفة ، يمكنك أيضًا دمج نظام التأجير الخاص بنا بسلاسة في عملك "
                                />
                            </div>
                        </div>
                        <AdvanceTabOneAr />
                    </div>
                </div>

                <div className="rwt-timeline-area rn-section-gap_home">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "كيفية العمل"
                                        title = "الحلول المثلى لك ولعملائك"
                                        description= "من خلال 4 خطوات بسيطة ، سيجري عملاؤك او موظفيك الحجوزات مباشرة من موقع الويب الخاص بك"
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwoAr classVar=""  />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}
                <Separator />
                <br />
                {/* Start Brand Area  */}
                <div className="rwt-brand-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "عملاء رائعين"
                                    title = " السمعة الجيدة تأتي فقط من الأسماء الجيدة "
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandTwoAr brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}
                <FooterThreeAr />
            </main>
        </>
    )
}

export default Marketing;
