import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterThree from '../common/footer/FooterThree';
import SlipThree from '../elements/split/SlipThree';
import SectionTitle from "../elements/sectionTitle/SectionTitle";


const SeoAgency = () => {
    return (
        <>
            <SEO title="Privacy and Company Info " />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Company Infos & Privacy"
                                    />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Elements Area  */}
                <div className="rwt-split-area">
                    <div className="wrapper">
                        <SlipThree />
                    </div>
                </div>
                {/* End Elements Area  */}
    
                <FooterThree />
            </main>
        </>
    )
}

export default SeoAgency
