import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title}|| Carlita The Perfect Rental & Fleet Management System - Reservations & Management</title>
            <meta name="robots" content="rental, car, rental, car rental, car, qatar, abu dhabi, saudi, arabia, dubai, management, software, reservation, booking, online, software, rent a car, car management, rental" />
            <meta name="description" content="No more Excel and paper. Save money and time with Carlita." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


