import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <div className="rn-address mt--0">
                        <div className="icon">
                            <FiMail />
                        </div>
                        <div className="inner">
                            <h4 className="title">Our Email Address</h4>
                            <p><a href="mailto:info@carlitarental.com">info@carlitarental.com</a></p>
                            <p><a href="mailto:info@smartstepqa.com">info@smartstepqa.com</a></p>
                        </div>
                    </div>
                    <div className="rn-address mt--20">
                        <div className="icon">
                            <FiHeadphones />
                        </div>
                        <div className="inner">
                            <h4 className="title">Contact Phone Number</h4>
                            <p> <img className='language_flag ml--10' src="./images/flag/qa.svg" /> <a href="tel:+97433302179">+974 3330 2179</a></p>
                            <p><img className='language_flag ml--10' src="./images/flag/qa.svg" /> <a href="tel:+97466103661">+974 66103661</a> </p>
                            <p><img className='language_flag ml--10' src="./images/flag/ge.svg" /> <a href="tel:+4915227365186">+49 152 27365 186</a> </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className='aboutusdescription'>
                        <ContactForm formStyle="contact-form-1" />
                    </div>
                </div>
            </div>

        </>
    )
}
export default ContactOne;