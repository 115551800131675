import React from 'react';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionTwo from '../accordion/AccordionTwo';

const mission = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <AccordionTwo customStyle="" />
                    </div>
                    <div className="col-lg-6 pt--30">
                        <div className="mission-title text-right">
                            <h2 className="title">أسئلة متكررة <br /> {" "}
                                <Typed className="theme-gradient"
                                    strings={[
                                        "نبقى بجانبك",
                                        "حتى النجاح",
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                />
                            </h2>
                            <p className='b2'>استخدم باقتنا الخاصة أو تواصل معنا حتى نتمكن من مساعدتك في اختيار أفضل حل لأعمالك الإيجارية</p>

                            <p className='b2'>
                            لدى كارليتا أيضًا إصدار مجاني مدته ١٤ يومًا وسيصبح متاحًا بعد التسجيل
                            <br/>
                            من خلال نظام التأجير الخاص بنا ، ستتمكن من إدارة أسطولك وإنشاء الحجز وتعديله واستخدام التخزين السحابي للنظام ، مما يعني أنك ستتمكن من إجراء الأعمال في أي زمان ومكان في العالم
                            </p>

                            <div className="read-more-btn mt--50">
                                <Link className="btn-default btn-icon" to="/contact"> تجربة مجانية <i className="icon"><FiArrowRight /></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                        
        </div>
    )
}
export default mission;
