import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} eventKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Can I manage my own rates in the system?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>Yes, the system can be configured to set the prices by day, week or month. In addition you could choose for a decreasing day rate structure. Lastly, you can set rates for additional services that you offer such as insurance, GPS, and child seats. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    In which language is the system available?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>The System is available in German, English and Arabic. But if you require a different language we will activate this language for you after your subscription.

                   </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Will my clients receive a confirmation email after they make an online reservation?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Yes, the system automatically generates confirmation emails for the Reservation, invoices, contract and cancellation.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Where is the application hosted?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Our hosting is based on your region. We have hosting options in Germany and USA.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Who will own the data?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>The data is owned by the customer. In any case of termination, we will provide ALL DATA to the client in a readable format such as SQL or CSV.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                 Can I cancel my subscription at any time?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                    <Card.Body>Yes, you can cancel every month or every year depending on the subscription you choose.</Card.Body>
                </Accordion.Collapse>
            </Card>


        </Accordion>
    )
}
export default AccordionOne

