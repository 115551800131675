import React from 'react';
import { FiCheck } from "react-icons/fi";

const PricingTwo = () => {
    return (
        <div className="row row--15">

            {/* Start PRicing Table Area  
            <div className="col-lg-3 col-md-6 col-12">
                <div className="rn-pricing style-4">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Standard</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">600</span>
                                </div>
                                <span className="subtitle">One-Time Fee</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Personal manager</li>
                                <li><FiCheck /> Site integration</li>
                                <li><FiCheck /> Payment system integration</li>
                                <li><FiCheck /> System setup</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
             End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-2">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Standard </h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">950</span>
                                </div>
                                <span className="subtitle">One-Time Fee</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Personal manager</li>
                                <li><FiCheck /> Personal modifications</li>
                                <li><FiCheck /> Site integration</li>
                                <li><FiCheck /> Payment system integration</li>
                                <li><FiCheck /> System setup</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-2 active">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Pro</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">2.100</span>
                                </div>
                                <span className="subtitle">One-Time Fee</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> installation your website</li>
                                <li><FiCheck /> Individual design</li>
                                <li><FiCheck /> Adaptive UI</li>
                                <li><FiCheck /> Website hosting</li>
                                <li><FiCheck /> 1 Domain</li>
                                <li><FiCheck /> Company Mails</li>
                                <li><FiCheck /> Backup and security</li>
                                <li><FiCheck /> Support</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

             {/* Start PRicing Table Area  */}
             <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing style-2">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Enterprise</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">2.599</span>
                                </div>
                                <span className="subtitle">One-Time Fee</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> installation your website</li>
                                <li><FiCheck /> Individual design</li>
                                <li><FiCheck /> Adaptive UI</li>
                                <li><FiCheck /> Personal modifications</li>
                                <li><FiCheck /> Website hosting</li>
                                <li><FiCheck /> 1 Domain</li>
                                <li><FiCheck /> Company Mails</li>
                                <li><FiCheck /> Backup and security</li>
                                <li><FiCheck /> SEO "Optimization"</li>
                                <li><FiCheck /> Apple iPad </li>
                                <li><FiCheck /> 24h/7 Support</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}
        </div>
    )
}
export default PricingTwo;