import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import StartWeb from "./pages/StartWeb";
import Price from "./pages/Price";
import PrivacyCompanyinfos from "./pages/PrivacyCompanyinfos";

// Pages import Here - Arabic
import المنتجات from "./pages/ar/المنتجات";
import الأسعار from "./pages/ar/الأسعار";
import الحماية from "./pages/ar/الحماية";


// elements import Here - Arabic
import AdvanceTabOneAr from "./elements/advancetab/AdvanceTabOneAr";
import AdvancePricingAr from "./elements/advancePricing/AdvancePricingAr";
import PricingTableAr from "./elements/advancePricing/PricingTableAr";
import ContactAr from "./elements/contact/ContactAr";

// Elements import Here 
import Elements from "./elements/elements/Elements";
import Button from "./elements/button/Button";

import RnAccordion from "./elements/accordion/RnAccordion";
import Pricing from "./elements/pricing/Pricing";
import Gallery from "./elements/gallery/Gallery";
import Contact from "./elements/contact/Contact";
import AdvanceTab from "./elements/advancetab/AdvanceTab";
import AdvancePricing from "./elements/advancePricing/AdvancePricing";
import PricingTable from "./elements/advancePricing/PricingTable";

import Error from "./pages/Error";


// Import Css Here 
import './assets/scss/style.scss';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={المنتجات}/> {/* */}
                    <Route path={`${process.env.PUBLIC_URL + "/StartWeb"}`} exact component={StartWeb}/> {/* */}
                    <Route path={`${process.env.PUBLIC_URL + "/Price"}`} exact component={Price}/>
                    <Route path={`${process.env.PUBLIC_URL + "/PrivacyCompanyinfos"}`} exact component={PrivacyCompanyinfos}/>
                    {/* Arabic pages */}
                    <Route path={`${process.env.PUBLIC_URL + "/ar/الأسعار"}`} exact component={الأسعار}/>
                    <Route path={`${process.env.PUBLIC_URL + "/ar/الحماية"}`} exact component={الحماية}/>
                    {/* Arabic pages */}
                    {/* Arabic elements */}
                    <Route path={`${process.env.PUBLIC_URL + "/advance-tab-ar"}`} exact component={AdvanceTabOneAr}/>
                    <Route path={`${process.env.PUBLIC_URL + "/ContactAr"}`} exact component={ContactAr}/>

                    <Route path={`${process.env.PUBLIC_URL + "/advance-pricing-ar"}`} exact component={AdvancePricingAr}/>
                    <Route path={`${process.env.PUBLIC_URL + "/pricing-table-ar"}`} exact component={PricingTableAr}/>
                    {/* Arabic elements */}
                    <Route path={`${process.env.PUBLIC_URL + "/elements"}`} exact component={Elements}/>
                    <Route path={`${process.env.PUBLIC_URL + "/button"}`} exact component={Button}/>
                    <Route path={`${process.env.PUBLIC_URL + "/accordion"}`} exact component={RnAccordion}/>
                    <Route path={`${process.env.PUBLIC_URL + "/pricing"}`} exact component={Pricing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} exact component={Gallery}/>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>
                    <Route path={`${process.env.PUBLIC_URL + "/advance-tab"}`} exact component={AdvanceTab}/>
                    <Route path={`${process.env.PUBLIC_URL + "/advance-pricing"}`} exact component={AdvancePricing}/>
                    <Route path={`${process.env.PUBLIC_URL + "/pricing-table"}`} exact component={PricingTable}/>

                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error}/>
                    


                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
