import React from 'react';
import SEO from "../../common/SEOAR";
import Separator from "../../elements/separator/Separator";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import HeaderOne from '../../common/header/HeaderOneAr';

import AccordionMobAr from '../../elements/accordion/AccordionMobAr';
import PricingTable from '../../elements/advancePricing/PricingTableAr';
import PricingTwo from '../../elements/pricing/PricingTwoAr';
import MissionAr from "../../elements/about/MissionAr";
import FooterThree from '../../common/footer/FooterThreeAr';



const WebAgency = () => {
    return (
        <>
            <SEO title="الأسعار والباقات " />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

                <br /><br />
                {/* Start Elements Area  */}
                <div className="rwt-pricingtable-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "خطة التسعير لدينا"
                                    title = "لن نشارك الأرباح معك في كل حجز، بل سوف نساعدك على الفوز بالمكاسب لنكسب السمعة الجيدة "
                                    description = " "
                                />
                            </div>
                        </div>
                 
                        <div className="row mt--30">
                            <div className="col-lg-12">
                                <AccordionMobAr />
                            </div>
                        </div>

                        <div className="row mt--30">
                            <div className="col-lg-12">
                                <PricingTable />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-pricing-area rn-section-gap-home mb--30">
                    <div className="container">
                        <div className="row mb--40 mb_sm--0">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "رسوم تدفع مرة واحدة"
                                        title = "اختر الباقة الأمثل لك"
                                        description = ""
                                    />
                            </div>
                        </div>
                        <PricingTwo />
                    </div>
                </div>
                {/* End Elements Area  */}
                <Separator /> 
                {/* Start Mission Area   */}
                <MissionAr />                    
                {/* Start Mission Area  */}
             
                <FooterThree />
            </main>
        </>
    )
}
export default WebAgency;
