import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="with-megamenu"><Link to="/startweb">Products</Link> </li>
            <li><Link to="/Price">Pricing</Link></li>
        </ul>
    )
}
export default Nav;
