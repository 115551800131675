import React from 'react'
import { FiCheck } from "react-icons/fi";


const AdvancePricingOne = () => {
    return (
        <div className="advance-pricing d-none d-lg-block">
            <div className="inner">
                <div className="row row--0">
                    <div className='col-lg-12'>
                        <div className="pricing-right">
                            <div className="pricing-offer text-right">
                                <table data-table="order-table" className='order-table'>
                                    <thead>
                                        <tr>
                                            <th><h5 className="price-title-table"> رجال الأعمال</h5> <p className='b1 mb--5'>١ دولار / لكل سيارة</p> </th>
                                            <th><h5 className="price-title-table">إنتربرايس</h5> <p className='b1 mb--5'>٤٩٩ دولار / شهريًا</p> </th>
                                            <th><h5 className="price-title-table">بروفيشنال </h5> <p className='b1 mb--5'>٢٩٩ دولار / شهريًا</p></th>
                                            <th><h5 className="price-title-table">عادي</h5> <p className='b1 mb--5'>١٨٠ دولار / شهريًا</p> </th>
                                            <th><h5 className="price-title-table">أساسي </h5> <p className='b1 mb--5'>٦٠ دولار / شهريًا</p> </th>
                                            <th><h5 className="price-title-table">مبتدأ </h5> <p className='b1 mb--5'>٤٠ دولار / شهريًا</p> </th>
                                            <th><h5 className="price-title-table mb--0">الميزات </h5></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>  ٥٠٠ + </td>
                                            <td>٣٠١ حتى ٥٠٠</td>
                                            <td>١٠١ حتى ٣٠٠</td>
                                            <td>٢٦ حتى ١٠٠</td>
                                            <td>١١ حتى ٢٥</td>
                                            <td>٠ حتى ١٠</td>
                                            <td>عدد المركبات </td>
                                        </tr>
                                        <tr>
                                            <td>مفتوح</td>
                                            <td>مفتوح</td>
                                            <td>مفتوح</td>
                                            <td>مفتوح</td>
                                            <td>مفتوح</td>
                                            <td>مفتوح</td>
                                            <td>عدد فروع الشركات </td>
                                        </tr>
                                        <tr>
                                            <td>مفتوح</td>
                                            <td>مفتوح</td>
                                            <td>مفتوح</td>
                                            <td>مفتوح</td>
                                            <td>مفتوح</td>
                                            <td>مفتوح</td>
                                            <td>عدد المستخدمين </td>
                                        </tr>

                                        <tr className='noborder mt--20'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='noborder'><h4 className='price-title-table mb--5'>الحلول</h4></td>
                                        </tr>
                              
                                        <tr>
                                            <td><FiCheck /> </td>
                                            <td><FiCheck /> </td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td> حجوزات العملاء اونلاين </td>
                                        </tr>
                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /> </td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td>العقود   </td>
                                        </tr>
                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td> إدارة اسطول السيارات </td>
                                        </tr>
                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td> إدارة الزبائن </td>
                                        </tr>
                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td>التقارير </td>
                                        </tr>
                                     
                                        <tr className='noborder mt--20'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='noborder'><h4 className='price-title-table mb--5'>تكاملات</h4></td>
                                        </tr>

                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td></td>
                                            <td> الدفع عبر الإنترنت </td>

                                        </tr>
                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td></td>
                                            <td></td>
                                            <td> الدمج مع الشركات </td>
                                        </tr>
                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td> API الوصول إلى </td>
                                           
                                        </tr>
                                                          
                                        <tr className='noborder mt--20'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='noborder'><h4 className='price-title-table mb--5'>إضافات</h4></td>
                                        </tr>

                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /> </td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td> عقود الإيجار الالكترونية </td>
                                        </tr>
                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td></td>
                                            <td>بوابة تسجيل دخول العملاء </td>
                                        </tr>
                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td>لغات متعددة</td>
                                        </tr>
                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td>التسعير الديناميكي </td>
                                        </tr>
                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td> الأمان والنسخ الاحتياطي </td>
                                        </tr>
                                        <tr className='noborder mt--20'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='noborder'><h4 className='price-title-table mb--5'>على متن الطائرة</h4></td>
                                        </tr>
                                        <tr>
                                            <td>٢٠ ساعة</td>
                                            <td>٢٠ ساعة</td>
                                            <td>١٠ ساعة</td>
                                            <td>٦ ساعة</td>
                                            <td>٤ ساعة</td>
                                            <td>٢ ساعة</td>
                                            <td> جلسات تنصيب النظام والتدريب </td>
                                        </tr>
                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td></td>
                                            <td></td>
                                            <td>وكيل نجاح مخصص </td>
                                        </tr>
                                        <tr>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>خدمة ٢٤ ساعة / ٧ أيام</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdvancePricingOne
