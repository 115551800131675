import React from 'react';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from '../accordion/AccordionOne';

const mission = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title">
                            <h2 className="title">FAQ <br /> {" "}
                                <Typed className="theme-gradient"
                                    strings={[
                                        "we stay next",
                                        "to you until",
                                        "the successful.",
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                />
                            </h2>
                            <p>Use our calculator or get in touch so we can help you choose the best solution for your rental business.</p>

                            <p>Carlita also has a 14-day free version which will become available immediately after registration.
                                With our rental system you will be able to manage your fleet, create and edit booking, use cloud storage of the system, which means you will be able to conduct business from anywhere in the world.</p>

                            <div className="read-more-btn mt--50">
                                <Link className="btn-default btn-icon" to="/contact">Free Trial <i className="icon"><FiArrowRight /></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <AccordionOne customStyle="" />
                    </div>
                </div>
            </div>                        
        </div>
    )
}
export default mission;
