import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/carlita_about.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">Corporate About.</span></h4>
                                    <h2 className="title mt--10">CARLITA RENTAL System</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>

                                    Experience and competence, accumulated over years of research in the rental sector, have allowed us to develop advanced web applications and create system that changes the way you work! Which has proven its effectiveness well-deserved in many regions in Germany. Our goal is to help rental companies run their businesses. Carlita is an easy, complete and powerful system designed to drive innovation in sales management. Which allows the business owner to view all profits and reservations anywhere and at any time
                                    <br /><br />
                                    No more manual excel lists and leases. With the Carlita reservation and rental system, you can automate your stressful activities that will save 75% of your work and time, and this is a guarantee that we are responsible for.
                                    Technologies ranging from marketing, customer presentations, reservations, online or office payments, contract processing, delivery and receipt to billing and damage assessment

                                    </p>
                                    {/* 
                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Track your teams progress with mobile app.</li>
                                        <li><span className="icon"><FiCheck /></span> Lorem ipsum dolor sit amet consectetur adipisicing.</li>
                                        <li><span className="icon"><FiCheck /></span> Ipsum dolor sit amet consectetur adipisicing.</li>
                                        <li><span className="icon"><FiCheck /></span> Your teams progress with mobile app.</li>
                                    </ul> */}
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="/contact">Testversion <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
