import React from 'react';
const callToActionData = {
    title: " The first time in Qatar. 👋 We market our system in the Middle East, especially in Qatar, United Arab Emirates, as well as Saudi Arabia. 🚀",
    subtitle: "",
    btnText: "vdfd",
}
const CalltoActionSix = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-6">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                    <div className="col-lg-4 col-12">
                        <div className="call-to-btn text-center text-lg-center mt_md--20 mt_sm--20">
                            <img className='wearehere' src="../images/about/wearehere2.png" />
                        </div>
                    </div>
                    <div className="col-lg-8 col-12">
                        <div className="inner">
                            <div className="content text-left">
                                <h2 className="title">{callToActionData.title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionSix;