import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
             <li><Link to="/ar/الأسعار">الأسعار</Link></li>
            <li className="with-megamenu"><Link to="/">لماذا نحن</Link> </li>
        </ul>
    )
}
export default Nav;
