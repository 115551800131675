import React from 'react';
import SEO from "../common/SEO";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import HeaderOne from '../common/header/HeaderOne';

import AccordionMob from '../elements/accordion/AccordionMob';
import PricingTable from '../elements/advancePricing/PricingTable';
import PricingTwo from '../elements/pricing/PricingTwo';
import Mission from "../elements/about/Mission";
import FooterThree from '../common/footer/FooterThree';



const WebAgency = () => {
    return (
        <>
            <SEO title="Pricing " />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

                <br /><br />
                {/* Start Elements Area  */}
                <div className="rwt-pricingtable-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Pricing Plan"
                                    title = ""
                                    description = "We will not share the profits with you on every booking, but rather help you earn profits so that we can win the good reputation."
                                />
                            </div>
                        </div>

                        <div className="row mt--30">
                            <div className="col-lg-12">
                                <AccordionMob />
                            </div>
                        </div>
                 
                        <div className="row mt--30">
                            <div className="col-lg-12">
                                <PricingTable />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-pricing-area rn-section-gap-home mb--30">
                    <div className="container">
                        <div className="row mb--40 mb_sm--0">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "One-Time Fee"
                                        title = "Choose the best package for you"
                                        description = ""
                                    />
                            </div>
                        </div>
                        <PricingTwo />
                    </div>
                </div>
                {/* End Elements Area  */}
                <Separator /> 
                {/* Start Mission Area   */}
                <Mission />                    
                {/* Start Mission Area  */}
             
                <FooterThree />
            </main>
        </>
    )
}
export default WebAgency;
