import {useState, useRef} from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/LogoAr";
import Nav from './NavAr';
import MobileMenu from './MobileMenu';
import MobileMenuAr from './MobileMenuAr';
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";
import {Link} from "react-router-dom";


const HeaderOne = ({btnStyle, HeaderSTyle}) => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }
    const ref = useRef();
    let [check] = useState(true);
    const sticky = useStickyHeader( 50 );
    const headerClasses = `header-default ${(sticky && check) ? 'sticky' : ''}`


    return (
        <>
            <header ref={ref} className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}>
                <div className="container position-relative">
                    <div className="row align-items-center">
                        <div className="col-lg-9 col-md-6 col-8 position-static">
                            <div className="header-right">
                                {/* A JSX comment */}
                                <div className="mobile-menu-bar  d-block d-lg-none">
                                    <div className="hamberger">
                                        <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                                    </div>
                                </div>
                                <Darkmode />
                                <div className="header-btn ml--10">    
                                    <Link className={`btn-default btn-small round btn-icon  ${btnStyle}`} to="/contactar"> النسخة التجريبية</Link>
                                </div> 
                                <nav className="mainmenu-nav d-none d-lg-block">
                                    <Nav />
                                </nav>
                            </div>  
                        </div>
                        <div className="col-lg-3 col-md-6 col-4">
                            <div className="header-right">
                                <Logo 
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo_ar.png`}
                                    image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark_ar.png`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <MobileMenuAr show={ofcanvasShow} onClose={onCanvasHandler}  />
        </>
    )
}
export default HeaderOne;