import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const AdvanceTabOne = () => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="order-2 order-lg-2 col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Admin Reservation</h4>
                                    {/*  <p className="description">Use Doob template you can build a corporate website a short time.</p> */}
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Online Reservation</h4>
                                    {/*  <p className="description">Use Doob template you can build a corporate website a short time.</p> */}
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Fleet Management</h4>
                                    {/*  <p className="description">Use Doob template you can build a corporate website a short time.</p> */}
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Fleet Management</h4>
                                    {/*  <p className="description">Use Doob template you can build a corporate website a short time.</p> */}
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Cloud solution</h4>
                                    {/*  <p className="description">Use Doob template you can build a corporate website a short time.</p> */}
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Go Paperless</h4>
                                    {/*  <p className="description">Use Doob template you can build a corporate website a short time.</p> */}
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <h4>Take booking quickly and efficiently</h4>
                                    <p>The reservations department will help you to take new reservations quickly, especially for customers saved in the system. With the daily plan, you can quickly and accurately manage and control all bookings in the calendar. You will be able to keep track of every minute schedule and daily schedule simply.</p>
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/why-us_1.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="tab-content">
                                    <h4>Let your customers book directly through your website</h4>
                                    <p> Increase your market reach and improve your customers experience by automating your rental process with our user-friendly website.
                                    <br />You can integrate your inventory into your existing website at any time using HTML code or a WordPress plugin. Your visitors can then make inquiries or book directly online. There are no limits to your design. All buttons, boxes and colors can be individually adapted to your branding.
                                        We offer packages at affordable prices for developing your custom website.
                                    
                                    </p>
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/why-us_2.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <h4>Car Management System</h4>
                                    <p> You will have time to process all orders and deliver the cars on time. And the ability to set prices and discounts according to days, months and years. It is also possible to put the car in maintenance condition. Not least, you can also save car damages in the system to facilitate the process of receiving and delivery as well as management.</p>
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/why-us_3.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                  
                                    <h4>Csr Management System</h4>
                                    <p>You don’t need to use messy sheets or hire extra administrative staff to become more productive anymore; with Carlita you’ll discover a set of tools made to easily manage your fleet’s availability, repairs, and finances.
                                        <br />With our clever Software, you will learn how to manage your time efficiently. </p>
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/why-us_7.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                
                                    <h4>Intelligent Cloud Solution</h4>
                                    <p>Carlita is 100% cloud-based software. You do not have to install our system on a computer, because you can log in from any device and from anywhere in the world where there is Internet.
                                        The best solution is to use it through the iPad for easy Pickup and Return process of the car and the electronic signature of customers.</p>
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/why-us_5.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <h4>Electronic signature - goodbye to papers</h4>
                                    <p> Carlita generates a rental agreement that can be customized to your requirements. When checking out (pickup or return) the customer, you can upload automatically pictures of the vehicle from our CMS and have the customer sign the agreement digitally. The customer will then receive the contract automatically by e-mail, as well as the invoice upon receipt of the car from us.</p>
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/why-us_6.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabOne;