import React from 'react'
import { FiCheck } from "react-icons/fi";


const AdvancePricingOne = () => {
    return (
        <div className="advance-pricing d-none d-lg-block">
            <div className="inner">
                <div className="row row--0">
                    <div className='col-lg-12'>
                        <div className="pricing-right">
                            <div className="pricing-offer">
                                <table className='order-table'>
                                    <thead className='order-table'>
                                        <tr>
                                            <th><h5 className="price-title-table mb--0">Features </h5></th>
                                            <th><h5 className="price-title-table">Starter </h5> <p className='mb--5 b1'>40 $ / month</p> </th>
                                            <th><h5 className="price-title-table">Basic </h5> <p className='mb--5 b1'>60 $ / month</p> </th>
                                            <th><h5 className="price-title-table">Standard</h5> <p className='mb--5 b1'>180 $ / month</p> </th>
                                            <th><h5 className="price-title-table-17">Professional </h5> <p className='mb--5 b1'>299 $ / month</p></th>
                                            <th><h5 className="price-title-table">Enterprise</h5> <p className='mb--5 b1'>499 $ / month</p> </th>
                                            <th><h5 className="price-title-table">Individual</h5> <p className='mb--5 b1'>1 $ / via car </p> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Number of Vehicles</td>
                                            <td>0-10</td>
                                            <td>11-25</td>
                                            <td>26-100</td>
                                            <td>101-300</td>
                                            <td>301-500</td>
                                            <td> > 500</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Branches</td>
                                            <td>Unlimited</td>
                                            <td>Unlimited</td>
                                            <td>Unlimited</td>
                                            <td>Unlimited</td>
                                            <td>Unlimited</td>
                                            <td>Unlimited</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Users</td>
                                            <td>Unlimited</td>
                                            <td>Unlimited</td>
                                            <td>Unlimited</td>
                                            <td>Unlimited</td>
                                            <td>Unlimited</td>
                                            <td>Unlimited</td>
                                        </tr>
                                
                                        <tr className='tabletitel' >
                                        <td className='tabletitel'><h4 className="price-title-table-19 mb--5">Solutions</h4></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        <tr>
                                            <td>Online Reservations </td>
                                            <td><FiCheck /> </td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>
                                        <tr>
                                            <td>Rental Agreements </td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /> </td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>
                                        <tr>
                                            <td>Fleet Management</td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>
                                        <tr>
                                            <td>Customer Management</td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>
                                        <tr>
                                            <td>Reporting</td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>
                                    
                                        <tr className='tabletitel' >
                                            <td className='tabletitel'><h4 className="price-title-table-19 mb--5">Integrations</h4></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        <tr>
                                            <td>Online Payments</td>
                                            <td></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>

                                        </tr>
                                        <tr>
                                            <td>Integration request</td>
                                            <td></td>
                                            <td></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>
                                        <tr>
                                            <td>API Access</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>

                                        <tr className='tabletitel' >
                                            <td className='tabletitel'><h4 className="price-title-table-19 mb--5">Extras</h4></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                        <tr>
                                            <td>Digital Signature </td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /> </td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>
                                        <tr>
                                            <td> Personal account for the client	</td>
                                            <td><FiCheck /> </td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>
                                        <tr>
                                            <td>Multiple Languages</td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>
                                        <tr>
                                            <td>Dynamic Pricing</td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>
                                        <tr>
                                            <td>Safety and Back-up</td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>
                                   
                                        <tr className='tabletitel' >
                                            <td className='tabletitel'><h4 className="price-title-table-19 mb--5">Onboarding</h4></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Configuration & Training Sessions</td>
                                            <td>2 Hour</td>
                                            <td>4 Hour</td>
                                            <td>6 Hour</td>
                                            <td>10 Hour</td>
                                            <td>20 Hour</td>
                                            <td>20 Hour</td>
                                            			
                                        </tr>
                                        <tr>
                                            <td>Dedicated Success Agent</td>
                                            <td></td>
                                            <td></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>
                                        <tr>
                                            <td>24/7 Support</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                            <td><FiCheck /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdvancePricingOne
