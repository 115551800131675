import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';
import { FiCheck } from "react-icons/fi";

const AccordionOne = ({customStyle}) => {
    return ( 
        <Accordion className={`rn-accordion-style d-lg-none  ${customStyle}`} eventKey="0">
            <Card>
                <Card.Header className=' pt--10 pb--10'>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    <div className='row align-items-center'>
                        <div className='col-6'>
                            <h4 className='mb--0'>40 $</h4>
                            <p className='b8 mb--0'>month</p>
                        </div> 
                        <div className='col-6'>
                            <h4 className='text-right mb--10'>Starter </h4>
                        </div> 
                    </div> 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body> 
                        <table className='mb--10'>
                            <th><h5 className="price-title mb--10 ">Features </h5></th>
                            <tr><FiCheck /> 0-10 Vehicles</tr>
                            <tr><FiCheck /> Unlimited Branches</tr>
                            <tr><FiCheck /> Unlimited Users</tr>
                            <th><h5 className="price-title mt--10 mb--10">Solutions </h5></th>
                            <tr><FiCheck /> Online Reservations</tr>
                            <tr><FiCheck /> Rental Agreements</tr>
                            <tr><FiCheck /> Fleet Management</tr>
                            <tr><FiCheck /> Customer Management</tr>
                            <tr><FiCheck /> Reporting</tr>
                            <th><h5 className="price-title mt--10 mb--10">Extras</h5></th>
                            <tr><FiCheck /> Digital Signature</tr>
                            <tr><FiCheck /> Personal account for the client</tr>
                            <tr><FiCheck /> Multiple Languages</tr>
                            <tr><FiCheck /> Dynamic Pricing</tr>
                            <tr><FiCheck /> Safety and Back-up</tr>
                            <th><h5 className="price-title mt--10 mb--10">Onboarding</h5></th>
                            <tr>2 Hours / Configuration & Training Sessions</tr>
                        </table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header className=' pt--10 pb--10'>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    <div className='row align-items-center'>
                        <div className='col-6'>
                            <h4 className='mb--0'>60 $</h4>
                            <p className='b8 mb--0'>month</p>
                        </div> 
                        <div className='col-6'>
                            <h4 className='text-right mb--10'>Basic </h4>
                        </div> 
                    </div> 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <table className='mb--10'>
                            <th><h5 className="price-title mb--10">Features </h5></th>
                            <tr><FiCheck /> 11-25 Vehicles</tr>
                            <tr><FiCheck /> Unlimited Branches</tr>
                            <tr><FiCheck /> Unlimited Users</tr>
                            <th><h5 className="price-title mt--10 mb--10">Solutions </h5></th>
                            <tr><FiCheck /> Online Reservations</tr>
                            <tr><FiCheck /> Rental Agreements</tr>
                            <tr><FiCheck /> Fleet Management</tr>
                            <tr><FiCheck /> Customer Management</tr>
                            <tr><FiCheck /> Reporting</tr>
                            <th><h5 className="price-title mt--10 mb--10">Integrations </h5></th>
                            <tr><FiCheck /> Online Payments</tr>
                            <th><h5 className="price-title mt--10 mb--10">Extras</h5></th>
                            <tr><FiCheck /> Digital Signature</tr>
                            <tr><FiCheck /> Personal account for the client</tr>
                            <tr><FiCheck /> Multiple Languages</tr>
                            <tr><FiCheck /> Dynamic Pricing</tr>
                            <tr><FiCheck /> Safety and Back-up</tr>
                            <th><h5 className="price-title mt--10 mb--10">Onboarding</h5></th>
                            <tr>4 Hours / Configuration & Training Sessions</tr>
                        </table>
                   </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header className=' pt--10 pb--10'>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    <div className='row align-items-center'>
                        <div className='col-6'>
                            <h4 className='mb--0'>180 $</h4>
                            <p className='b8 mb--0'>month</p>
                        </div> 
                        <div className='col-6'>
                            <h4 className='text-right mb--10'>Standard </h4>
                        </div> 
                    </div> 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>
                        <table className='mb--10'>
                            <th><h5 className="price-title mb--10">Features </h5></th>
                            <tr><FiCheck /> 26-100 Vehicles</tr>
                            <tr><FiCheck /> Unlimited Branches</tr>
                            <tr><FiCheck /> Unlimited Users</tr>
                            <th><h5 className="price-title mt--10 mb--10">Solutions </h5></th>
                            <tr><FiCheck /> Online Reservations</tr>
                            <tr><FiCheck /> Rental Agreements</tr>
                            <tr><FiCheck /> Fleet Management</tr>
                            <tr><FiCheck /> Customer Management</tr>
                            <tr><FiCheck /> Reporting</tr>
                            <th><h5 className="price-title mt--10 mb--10">Integrations </h5></th>
                            <tr><FiCheck /> Online Payments</tr>
                            <tr><FiCheck /> Integration request</tr>
                            <th><h5 className="price-title mt--10 mb--10">Extras</h5></th>
                            <tr><FiCheck /> Digital Signature</tr>
                            <tr><FiCheck /> Personal account for the client</tr>
                            <tr><FiCheck /> Multiple Languages</tr>
                            <tr><FiCheck /> Dynamic Pricing</tr>
                            <tr><FiCheck /> Safety and Back-up</tr>
                            <th><h5 className="price-title mt--10 mb--10">Onboarding</h5></th> 
                            <tr><FiCheck /> Dedicated Success Agent</tr>
                            <tr>6 Hours / Configuration & Training Sessions</tr>
                        </table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header className='pt--10 pb--10'>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    <div className='row align-items-center'>
                        <div className='col-6'>
                            <h4 className='mb--0'>299 $</h4>
                            <p className='b8 mb--0'>month</p>
                        </div> 
                        <div className='col-6'>
                            <h4 className='text-right mb--10'>Professional </h4>
                        </div> 
                    </div> 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>
                        <table className='mb--10'>
                            <th><h5 className="price-title mb--10">Features </h5></th>
                            <tr><FiCheck /> 101-200 Vehicles</tr>
                            <tr><FiCheck /> Unlimited Branches</tr>
                            <tr><FiCheck /> Unlimited Users</tr>
                            <th><h5 className="price-title mt--10 mb--10">Solutions </h5></th>
                            <tr><FiCheck /> Online Reservations</tr>
                            <tr><FiCheck /> Rental Agreements</tr>
                            <tr><FiCheck /> Fleet Management</tr>
                            <tr><FiCheck /> Customer Management</tr>
                            <tr><FiCheck /> Reporting</tr>
                            <th><h5 className="price-title mt--10 mb--10">Integrations </h5></th>
                            <tr><FiCheck /> Online Payments</tr>
                            <tr><FiCheck /> Integration request</tr>
                            <tr><FiCheck /> API Access</tr>
                            <th><h5 className="price-title mt--10 mb--10">Extras</h5></th>
                            <tr><FiCheck /> Digital Signature</tr>
                            <tr><FiCheck /> Personal account for the client</tr>
                            <tr><FiCheck /> Multiple Languages</tr>
                            <tr><FiCheck /> Dynamic Pricing</tr>
                            <tr><FiCheck /> Safety and Back-up</tr>
                            <th><h5 className="price-title mt--10 mb--10">Onboarding</h5></th> 
                            <tr><FiCheck /> 24/h</tr>
                            <tr><FiCheck /> Dedicated Success Agent</tr>
                            <tr>10 Hours / Configuration & Training Sessions</tr>
                        </table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header className=' pt--10 pb--10'>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    <div className='row align-items-center'>
                        <div className='col-6'>
                            <h4 className='mb--0'>499 $</h4>
                            <p className='b8 mb--0'>month</p>
                        </div> 
                        <div className='col-6'>
                            <h4 className='text-right mb--10'>Enterprise</h4>
                        </div> 
                    </div> 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>
                        <table className='mb--10'>
                            <th><h5 className="price-title mb--10">Features </h5></th>
                            <tr><FiCheck /> > 200 Vehicles</tr>
                            <tr><FiCheck /> Unlimited Branches</tr>
                            <tr><FiCheck /> Unlimited Users</tr>
                            <th><h5 className="price-title mt--10 mb--10">Solutions </h5></th>
                            <tr><FiCheck /> Online Reservations</tr>
                            <tr><FiCheck /> Rental Agreements</tr>
                            <tr><FiCheck /> Fleet Management</tr>
                            <tr><FiCheck /> Customer Management</tr>
                            <tr><FiCheck /> Reporting</tr>
                            <th><h5 className="price-title mt--10 mb--10">Integrations </h5></th>
                            <tr><FiCheck /> Online Payments</tr>
                            <tr><FiCheck /> Integration request</tr>
                            <tr><FiCheck /> API Access</tr>
                            <th><h5 className="price-title mt--10 mb--10">Extras</h5></th>
                            <tr><FiCheck /> Digital Signature</tr>
                            <tr><FiCheck /> Personal account for the client</tr>
                            <tr><FiCheck /> Multiple Languages</tr>
                            <tr><FiCheck /> Dynamic Pricing</tr>
                            <tr><FiCheck /> Safety and Back-up</tr>
                            <th><h5 className="price-title mt--10 mb--10">Onboarding</h5></th> 
                            <tr><FiCheck /> 24/h</tr>
                            <tr><FiCheck /> Dedicated Success Agent</tr>
                            <tr>20 Hours / Configuration & Training Sessions</tr>
                        </table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header className=' pt--10 pb--10'>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    <div className='row align-items-center'>
                        <div className='col-6'>
                            <h4 className='mb--0'>1 $</h4>
                            <p className='b8 mb--0'>via car</p>
                        </div> 
                        <div className='col-6'>
                            <h4 className='text-right mb--10'>Individual</h4>
                        </div> 
                    </div> 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                    <Card.Body>
                        <table className='mb--10'>
                            <th><h5 className="price-title mb--10">Features </h5></th>
                            <tr><FiCheck /> > 500 Vehicles</tr>
                            <tr><FiCheck /> Unlimited Branches</tr>
                            <tr><FiCheck /> Unlimited Users</tr>
                            <th><h5 className="price-title mt--10 mb--10">Solutions </h5></th>
                            <tr><FiCheck /> Online Reservations</tr>
                            <tr><FiCheck /> Rental Agreements</tr>
                            <tr><FiCheck /> Fleet Management</tr>
                            <tr><FiCheck /> Customer Management</tr>
                            <tr><FiCheck /> Reporting</tr>
                            <th><h5 className="price-title mt--10 mb--10">Integrations </h5></th>
                            <tr><FiCheck /> Online Payments</tr>
                            <tr><FiCheck /> Integration request</tr>
                            <tr><FiCheck /> API Access</tr>
                            <th><h5 className="price-title mt--10 mb--10">Extras</h5></th>
                            <tr><FiCheck /> Digital Signature</tr>
                            <tr><FiCheck /> Personal account for the client</tr>
                            <tr><FiCheck /> Multiple Languages</tr>
                            <tr><FiCheck /> Dynamic Pricing</tr>
                            <tr><FiCheck /> Safety and Back-up</tr>
                            <th><h5 className="price-title mt--10 mb--10">Onboarding</h5></th> 
                            <tr><FiCheck /> 24/h</tr>
                            <tr><FiCheck /> Dedicated Success Agent</tr>
                            <tr>20 Hours / Configuration & Training Sessions</tr>
                        </table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>


        </Accordion>
    )
}
export default AccordionOne

