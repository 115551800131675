import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="../images/about/carlita_about_ar.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient ">عن الشركة</span></h4>
                                    <h2 className="title mt--10 text-right"> كارليتا برنامج الإيجار الخاص بك </h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p className='aboutusdescription'>
                                        الخبرة والكفاءة ، المتراكمة على مدار سنوات من الأبحاث في قطاع الإيجارات ، سمحت لنا بتطوير تطبيقات ويب متقدمة وإنشاء برنامج يغير طريقة عملك! الذي اثبت فعاليته بجدارة في مناطق عديدة في المانيا.
                                        هدفنا هو مساعدة شركات التأجير على إدارة أعمالهم. يعتبر كارليتا برنامجًا سهلًا وكاملًا وقويًا يهدف إلى تحقيق الابتكار في إدارة المبيعات. الذي يسمح لصاحب العمل بعرض جميع المرابح والحجوزات في اي مكان وفي اي وقت
                                                                                
                                        <br /><br />
                                        لا مزيد من قوائم إكسل وعقود الإيجار اليدوية. مع نظام الحجز والتأجير كارليتا يمكنك أتمتة الأنشطة المرهقة عليك التي ستقوم بتوفير٧٥% من العمل والوقت وهذا ضمان نحن مسؤلين عنه.
                                        <br />
                                        التقنيات تبدأ من التسويق, عروض العملاء, الحجز, الدفع عبر الإنترنت او المكتب, معالجة العقود, التسليم والاستلام حتى الفواتير وتقييم الأضرار   
                                    </p>
                                    {/* 
                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Track your teams progress with mobile app.</li>
                                        <li><span className="icon"><FiCheck /></span> Lorem ipsum dolor sit amet consectetur adipisicing.</li>
                                        <li><span className="icon"><FiCheck /></span> Ipsum dolor sit amet consectetur adipisicing.</li>
                                        <li><span className="icon"><FiCheck /></span> Your teams progress with mobile app.</li>
                                    </ul> */}
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="/contactar"> النسخة التجريبية <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
