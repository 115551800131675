import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree';

import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AboutTwo from "../elements/about/AboutTwo";
import AdvanceTabOne from '../elements/advancetab/AdvanceTabOne';
import BrandTwo from '../elements/brand/BrandTwo';
import ServiceThree from '../elements/service/ServiceThree';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import CalltoActionSix from "../elements/calltoaction/CalltoActionSix";


const Marketing = () => {
    return (
        <>
            <SEO title="Home " />
            <main className="page-wrapper">
                
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-left">
                                    <h1 className="title theme-gradient display-four"> The rental manager that sets you apart every time! <br />{" "}
                                        <Typed
                                            strings={[
                                                "Car Rental",
                                                "Leasing",
                                                "Boat Rental",
                                                "RV Rental",
                                                "Motorcycles",
                                                "Construction MC",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description"> With Carlita, experience an all-in-one Rental System that allows you to run your business even more efficiently. Administration, planning and billing combined in one intuitive system.</p>
                                    <div className="button-group">
                                       {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="#">New Customer <i className="icon"><FiArrowRight /></i></Link> */} 
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contact Us <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/bg/cover-right_ca.png" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CalltoActionSix />
  
                <Separator />
                {/* Start Service Area  */}
                    <ServiceThree 
                        serviceStyle = "service__style--2"
                        textAlign = "text-center"
                    />
                    <br />
                {/* End Service Area  */}
                <Separator />

                {/* End Slider Area  */}
                <AboutTwo />
                {/* Start Service Area  */}
                <Separator />


                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "You can acquire all."
                                    title = "RENTING IS VERSATILE, CARLITA TOO"
                                    description = "With Carlita you can digitally map and optimize your entire rental and sales process."
                                />
                            </div>
                        </div>
                        <AdvanceTabOne />
                    </div>
                </div>

                <div className="rwt-timeline-area rn-section-gap_home">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "HOW IT WORKS"
                                        title = "Optimal Solutions For You And Your Customers"
                                        description= "With 4 simple steps your customers will be making bookings directly from your website."
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar=""  />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}
                <Separator />
                <br />
                {/* Start Brand Area  */}
                <div className="rwt-brand-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Awesome Clients"
                                    title = "A GOOD NAME COMES FROM MANY GOOD NAMES!"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandTwo brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}
                <FooterThree />
            </main>
        </>
    )
}

export default Marketing;
