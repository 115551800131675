import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionTwo = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} eventKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                هل يمكنني إدارة أسعاري الخاصة في النظام
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>نعم ، يمكن تهيئة النظام لضبط الأسعار باليوم أو الأسبوع أو الشهر. بالإضافة إلى ذلك ، يمكنك اختيار هيكل سعر يوم اوتماتيك. أخيرًا ، يمكنك تعيين أسعار للخدمات الإضافية التي تقدمها مثل التأمين ونظام تحديد المواقع العالمي ج-ب-س ومقاعد الأطفال والكثير</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                بأي لغة يتوفر البرنامج
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>البرنامج متوفر باللغات الألمانية والإنجليزية والعربية. ولكن إذا كنت بحاجة إلى لغة مختلفة ، فسنقوم بتنشيط هذه اللغة لك بعد اشتراكك

                   </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                هل سيتلقى عملائي رسالة تأكيد بالبريد الإلكتروني بعد قيامهم بالحجز عبر الإنترنت
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>نعم ، يقوم النظام تلقائيًا بإنشاء رسائل بريد إلكتروني لتأكيد الحجز والفواتير والعقد والإلغاء</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                أين يتم استضافة التطبيق؟
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body> استضافتنا تعتمد على منطقتك. لدينا خيارات استضافة في ألمانيا والولايات المتحدة الأمريكية </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                من سيمتلك البيانات؟ 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body> البيانات مملوكة للعميل. في أي حالة من حالات الإنهاء ، سنوفر كل البيانات للعميل بتنسيق يمكن قراءته </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                هل يمكنني إلغاء اشتراكي في أي وقت؟
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                    <Card.Body> نعم ، يمكنك الإلغاء كل شهر أو كل عام حسب الاشتراك الذي تختاره </Card.Body>
                </Accordion.Collapse>
            </Card>


        </Accordion>
    )
}
export default AccordionTwo

