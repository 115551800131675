import React from 'react';
import SEO from "../../common/SEOAR";
import Layout from "../../common/LayoutAr";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOneAr from "./ContactOneAr";

const Contact = () => {
    return (
        <>
            <SEO title="الاتصال " />
            <Layout>
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap pt--60">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "اتصل بنا"
                                        title = " جرب نظام كارليتا مجانًا لمدة ٧ أيام "
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ContactOneAr />               
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Contact;