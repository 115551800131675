import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';
import { FiCheck } from "react-icons/fi";

const AccordionOne = ({customStyle}) => {
    return ( 
        <Accordion className={`rn-accordion-style d-lg-none  ${customStyle}`} eventKey="0">
            <Card>
                <Card.Header className=' pt--10 pb--10'>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    <div className='row align-items-center'>
                        <div className='col-6'>
                            <h4 className='mb--0'>٤٠ دولار</h4>
                            <p className='b8 mb--0'>شهريًا</p>
                        </div> 
                        <div className='col-6'>
                            <h4 className='text-right mb--10'>مبتدأ </h4>
                        </div> 
                    </div> 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body> 
                        <table className='mb--10 text-right'>
                            <th><h5 className="price-title mb--10 ">الميزات </h5></th>
                            <tr>٠ حتى ١٠ سيارة <FiCheck /> </tr>
                            <tr> مفتوح <FiCheck /></tr>
                            <tr> مفتوح <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">الحلول </h5></th>
                            <tr>حجوزات العملاء اونلاين <FiCheck /> </tr>
                            <tr> العقود <FiCheck /></tr>
                            <tr> إدارة اسطول السيارات <FiCheck /></tr>
                            <tr> إدارة الزبائن <FiCheck /></tr>
                            <tr> التقارير <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">إضافات</h5></th>
                            <tr>عقود الإيجار الالكترونية <FiCheck /></tr>
                            <tr>لغات متعددة <FiCheck /></tr>
                            <tr> التسعير الديناميكي <FiCheck /></tr>
                            <tr> الأمان والنسخ الاحتياطي <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">على متن الطائرة</h5></th>
                            <tr> ٢ ساعة / جلسات تنصيب النظام والتدريب </tr>
                        </table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header className=' pt--10 pb--10'>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    <div className='row align-items-center'>
                        <div className='col-6'>
                            <h4 className='mb--0'>٦٠ دولار</h4>
                            <p className='b8 mb--0'>شهريًا</p>
                        </div> 
                        <div className='col-6'>
                            <h4 className='text-right mb--10'>أساسي </h4>
                        </div> 
                    </div> 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <table className='mb--10 text-right'>
                            <th><h5 className="price-title mb--10 ">الميزات </h5></th>
                            <tr> ١١ حتى ٢٥ سيارة <FiCheck /> </tr>
                            <tr> مفتوح <FiCheck /></tr>
                            <tr> مفتوح <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">الحلول </h5></th>
                            <tr>حجوزات العملاء اونلاين <FiCheck /> </tr>
                            <tr> العقود <FiCheck /></tr>
                            <tr> إدارة اسطول السيارات <FiCheck /></tr>
                            <tr> إدارة الزبائن <FiCheck /></tr>
                            <tr> التقارير <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10"> تكاملات </h5></th>
                            <tr> الدفع عبر الإنترنت <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">إضافات</h5></th>
                            <tr>عقود الإيجار الالكترونية <FiCheck /></tr>
                            <tr>  بوابة تسجيل دخول العملاء <FiCheck /></tr>
                            <tr>لغات متعددة <FiCheck /></tr>
                            <tr> التسعير الديناميكي <FiCheck /></tr>
                            <tr> الأمان والنسخ الاحتياطي <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">على متن الطائرة</h5></th>
                            <tr> ٤ ساعة / جلسات تنصيب النظام والتدريب </tr>
                        </table>
                   </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header className=' pt--10 pb--10'>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    <div className='row align-items-center'>
                        <div className='col-6'>
                            <h4 className='mb--0'> ١٨٠ دولار </h4>
                            <p className='b8 mb--0'>شهريًا</p>
                        </div> 
                        <div className='col-6'>
                            <h4 className='text-right mb--10'>عادي </h4>
                        </div> 
                    </div> 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>
                        <table className='mb--10 text-right'>
                            <th><h5 className="price-title mb--10 ">الميزات </h5></th>
                            <tr> ٢٦ حتى ١٠٠ <FiCheck /> </tr>
                            <tr> مفتوح <FiCheck /></tr>
                            <tr> مفتوح <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">الحلول </h5></th>
                            <tr>حجوزات العملاء اونلاين <FiCheck /> </tr>
                            <tr> العقود <FiCheck /></tr>
                            <tr> إدارة اسطول السيارات <FiCheck /></tr>
                            <tr> إدارة الزبائن <FiCheck /></tr>
                            <tr> التقارير <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10"> تكاملات </h5></th>
                            <tr> الدفع عبر الإنترنت <FiCheck /></tr>
                            <tr> الدمج مع الشركات <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">إضافات</h5></th>
                            <tr>عقود الإيجار الالكترونية <FiCheck /></tr>
                            <tr>  بوابة تسجيل دخول العملاء <FiCheck /></tr>
                            <tr>لغات متعددة <FiCheck /></tr>
                            <tr> التسعير الديناميكي <FiCheck /></tr>
                            <tr> الأمان والنسخ الاحتياطي <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">على متن الطائرة</h5></th>
                            <tr> ٦ ساعة / جلسات تنصيب النظام والتدريب </tr>
                        </table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header className='pt--10 pb--10'>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    <div className='row align-items-center'>
                        <div className='col-6'>
                            <h4 className='mb--0'>٢٩٩ دولار</h4>
                            <p className='b8 mb--0'>شهريًا</p>
                        </div> 
                        <div className='col-6'>
                            <h4 className='text-right mb--10'>بروفيشنال </h4>
                        </div> 
                    </div> 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>
                        <table className='mb--10 text-right'>
                            <th><h5 className="price-title mb--10 ">الميزات </h5></th>
                            <tr> ١٠١ حتى ٣٠٠ سيارة <FiCheck /> </tr>
                            <tr> مفتوح <FiCheck /></tr>
                            <tr> مفتوح <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">الحلول </h5></th>
                            <tr>حجوزات العملاء اونلاين <FiCheck /> </tr>
                            <tr> العقود <FiCheck /></tr>
                            <tr> إدارة اسطول السيارات <FiCheck /></tr>
                            <tr> إدارة الزبائن <FiCheck /></tr>
                            <tr> التقارير <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10"> تكاملات </h5></th>
                            <tr> الدفع عبر الإنترنت <FiCheck /></tr>
                            <tr> الدمج مع الشركات <FiCheck /></tr>
                            <tr> API الوصول إلى <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">إضافات</h5></th>
                            <tr>عقود الإيجار الالكترونية <FiCheck /></tr>
                            <tr>  بوابة تسجيل دخول العملاء <FiCheck /></tr>
                            <tr>لغات متعددة <FiCheck /></tr>
                            <tr> التسعير الديناميكي <FiCheck /></tr>
                            <tr> الأمان والنسخ الاحتياطي <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">على متن الطائرة</h5></th>
                            <tr> ١٠ ساعة / جلسات تنصيب النظام والتدريب </tr>
                        </table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header className=' pt--10 pb--10'>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    <div className='row align-items-center'>
                        <div className='col-6'>
                            <h4 className='mb--0'> ٤٩٠ دولار </h4>
                            <p className='b8 mb--0'>شهريًا</p>
                        </div> 
                        <div className='col-6'>
                            <h4 className='text-right mb--10'>إنتربرايس</h4>
                        </div> 
                    </div> 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>
                        <table className='mb--10 text-right'>
                            <th><h5 className="price-title mb--10 ">الميزات </h5></th>
                            <tr> ٣٠١ حتى ٥٠٠ سيارة  <FiCheck /> </tr>
                            <tr> مفتوح <FiCheck /></tr>
                            <tr> مفتوح <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">الحلول </h5></th>
                            <tr> حجوزات العملاء اونلاين <FiCheck /> </tr>
                            <tr> العقود <FiCheck /></tr>
                            <tr> إدارة اسطول السيارات <FiCheck /></tr>
                            <tr> إدارة الزبائن <FiCheck /></tr>
                            <tr> التقارير <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10"> تكاملات </h5></th>
                            <tr> الدفع عبر الإنترنت <FiCheck /></tr>
                            <tr> الدمج مع الشركات <FiCheck /></tr>
                            <tr> API الوصول إلى <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">إضافات</h5></th>
                            <tr>عقود الإيجار الالكترونية <FiCheck /></tr>
                            <tr>  بوابة تسجيل دخول العملاء <FiCheck /></tr>
                            <tr>لغات متعددة <FiCheck /></tr>
                            <tr> التسعير الديناميكي <FiCheck /></tr>
                            <tr> الأمان والنسخ الاحتياطي <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">على متن الطائرة</h5></th>
                            <tr> ٢٠ ساعة / جلسات تنصيب النظام والتدريب </tr>
                        </table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header className=' pt--10 pb--10'>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    <div className='row align-items-center'>
                        <div className='col-6'>
                        <h4 className='mb--0'>١ دولار</h4>
                            <p className='b8 mb--0'>لكل سيارة</p>
                    
                        </div> 
                        <div className='col-6'>
                            <h4 className='text-right mb--10'> رجال الأعمال</h4>
                        </div> 
                    </div> 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                    <Card.Body>
                        <table className='mb--10 text-right'>
                            <th><h5 className="price-title mb--10 ">الميزات </h5></th>
                            <tr>  ٥٠٠ سيارة + <FiCheck /> </tr>
                            <tr> مفتوح <FiCheck /></tr>
                            <tr> مفتوح <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">الحلول </h5></th>
                            <tr>حجوزات العملاء اونلاين <FiCheck /> </tr>
                            <tr> العقود <FiCheck /></tr>
                            <tr> إدارة اسطول السيارات <FiCheck /></tr>
                            <tr> إدارة الزبائن <FiCheck /></tr>
                            <tr> التقارير <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10"> تكاملات </h5></th>
                            <tr> الدفع عبر الإنترنت <FiCheck /></tr>
                            <tr> الدمج مع الشركات <FiCheck /></tr>
                            <tr> API الوصول إلى <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">إضافات</h5></th>
                            <tr>عقود الإيجار الالكترونية <FiCheck /></tr>
                            <tr>  بوابة تسجيل دخول العملاء <FiCheck /></tr>
                            <tr>لغات متعددة <FiCheck /></tr>
                            <tr> التسعير الديناميكي <FiCheck /></tr>
                            <tr> الأمان والنسخ الاحتياطي <FiCheck /></tr>
                            <th><h5 className="price-title mt--10 mb--10">على متن الطائرة</h5></th>
                            <tr>  خدمة ٢٤ ساعة / ٧ أيام <FiCheck /></tr>
                            <tr>  وكيل نجاح مخصص <FiCheck /></tr>
                            <tr> ٢٠ ساعة / جلسات تنصيب النظام والتدريب </tr>
                        </table>
                    </Card.Body> 
                </Accordion.Collapse>
            </Card>


        </Accordion>
    )
}
export default AccordionOne

