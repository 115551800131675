import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const AdvanceTabOne = () => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="order-2 order-lg-1 col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title"> حجوزات الموظف </h4>
                                    {/*  <p className="description">Use Doob template you can build a corporate website a short time.</p> */}
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title"> حجوزات العملاء اونلاين </h4>
                                    {/*  <p className="description">Use Doob template you can build a corporate website a short time.</p> */}
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">إدارة الأسطول</h4>
                                    {/*  <p className="description">Use Doob template you can build a corporate website a short time.</p> */}
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">إدارة الحجوزات</h4>
                                    {/*  <p className="description">Use Doob template you can build a corporate website a short time.</p> */}
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title"> حل السحابة </h4>
                                    {/*  <p className="description">Use Doob template you can build a corporate website a short time.</p> */}
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">لا اوراق بعد الآن</h4>
                                    {/*  <p className="description">Use Doob template you can build a corporate website a short time.</p> */}
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <div className='aboutusdescription pt--20'>
                                        <h4> أخذ الحجوزات بسرعة وكفاءة  </h4>
                                        <p className='b2'> سيساعدك قسم الحجوزات في إجراء الحجوزات الجديدة بسرعة كبيرة، خاصة للعملاء المحفوظين من قبل في النظام, لا يهم ان كانت الحجوزات عن طريق المكتب او هاتفيا. باستخدام الخطة اليومية ، يمكنك إدارة جميع الحجوزات في التقويم والتحكم فيها بسرعة ودقة. سوف تكون قادرًا ببساطة على تتبع كل الخطوات في كل دقيقة وثانية  </p>
                                    </div>
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="../images/tab/why-us_1.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="tab-content">
                                    <div className='aboutusdescription pt--20'>
                                        <h4>دع عملائك يحجزون مباشرة من خلال موقع الويب الخاص بك</h4>
                                        <p className='b2'> قم بزيادة وصولك إلى السوق وتحسين تجربة عملائك من خلال أتمتة عملية التأجير الخاصة بك من خلال موقعنا السهل الاستخدام للعملاء
                                        <br />
                                        يمكنك دمج صفحتك في موقع الويب الحالي الخاص بك في أي وقت باستخدام كود ه-ت-م-ل أو مطور وردبريس الإضافي. يمكن للزائرين بعد ذلك إجراء استفسارات أو الحجز مباشرة عبر الإنترنت. لا توجد حدود لتصميمك. يمكن تكييف جميع الأزرار والصناديق والألوان مع علامتك التجارية. نحن نقدم حزم بأسعار معقولة لتطوير موقع الويب المخصص الخاص بك
                                        </p>
                                    </div>
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="../images/tab/why-us_2.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className='aboutusdescription pt--20'>
                                        <h4> نظام إدارة السيارات </h4>
                                        <p className='b2'> سيكون لديك الوقت لمعالجة جميع الطلبات وتسليم السيارات في الوقت المحدد. والقدرة على تحديد الأسعار والخصومات حسب الأيام والأشهر والسنوات. من الممكن أيضًا وضع السيارة في حالة الصيانة.  كذلك  حفظ أضرار السيارة في النظام لتسهيل عملية الاستلام والتسليم </p>
                                    </div>
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="../images/tab/why-us_3.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className='aboutusdescription pt--20'>
                                    <h4> معالجة الحجوزات الذكي </h4>
                                    <p className='b2'>   لا تحتاج إلى استخدام أوراق فوضويّة أو تعيين موظفين إداريين إضافيين لتصبح أكثر إنتاجية بعد الآن ؛ مع كارليتا ، ستكتشف مجموعة من الأدوات داخل النظام لإدارة توفر أسطولك وإصلاحاته وشؤونه المالية بسهولة. مع برنامجنا الذكي ، ستتعلم كيفية إدارة وقتك بكفاءة 
                                    </p>
                                    </div>
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="../images/tab/why-us_7.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className='aboutusdescription'>
                                        <h4>حل السحابة الذكي</h4>
                                        <p className='b2'> كارليتا هو نظام قائم على السحابة بنسبة 100٪  لست مضطرًا إلى تثبيته على جهاز كمبيوتر ، لأنك تستطيع تسجيل الدخول من أي جهاز ومن أي مكان في العالم. أفضل حل هو استخدامه من خلال جهاز آيباد لسهولة عملية استلام السيارة وإعادتها والتوقيع الإلكتروني للعملاء </p>
                                    </div>
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="../images/tab/why-us_5.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className='aboutusdescription pt--20'>
                                        <h4>التوقيع الإلكتروني - وداعا للأوراق</h4>
                                        <p className='b1'>تقوم كارليتا بإنشاء عقود إيجار يمكن تخصيصها وفقًا لمتطلباتك. عند التحقق من (استلام أو إرجاع) العميل ، يمكنك تحميل صور السيارة تلقائيًا من نظام إدارة المحتوى لدينا وتوقيع العميل للعقد بشكلا رقميًا. بعد ذلك يستلم العميل العقد تلقائيًا عن طريق البريد الإلكتروني ، وكذلك الفاتورة بعد استلام السيارة منا </p>
                                    </div>
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="../images/tab/why-us_6.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabOne;