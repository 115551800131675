import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiPhone } from "react-icons/fi";
import {Link} from "react-router-dom";

const HeaderTopBar = () => {
    return (
        <div className="header-top-bar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-6"> {/*
                        <div className="header-left">
                            <ul className="social-icon social-default icon-naked">
                                <li><Link to="facebook.com"><FiFacebook /></Link></li>
                                <li><Link to="twitter.com"><FiTwitter /></Link></li>
                                <li><Link to="instagram.com"><FiInstagram /></Link></li>
                            </ul>
                        </div> */}
                    </div>
                    <div className="col-6">
                        <div className="header-right-language">
                            <ul className="social-icon social-default icon-naked">
                                <li className='flag_text'>عربي <Link to="/">  <img className='language_flag' src="./images/flag/sa.svg" /></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopBar;
